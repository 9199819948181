import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { useForm } from 'react-hook-form'

import createConfig from '../../../../site.config'

import ContactForm from '@/component/Composition/ContactForm'
import schemataResolver from 'react-hook-form-schemata-resolver'
import createContactFormSchema from '../../../../../components/service/widgets/general/contact-form/form-schema'

const config = createConfig()

const ContactFormWidget = ({ title, formType, country, __url }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    setError: setFormError,
    handleSubmit
  } = useForm({
    resolver: schemataResolver(createContactFormSchema(formType, country), {
      set: formType
    })
  })
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadRecaptcha = () => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        resolve(window.grecaptcha)
      } else {
        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaSiteKey}`
        script.onload = () => resolve(window.grecaptcha)
        script.onerror = reject
        document.head.appendChild(script)
      }
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    setError(null)
    setSuccess(false)

    try {
      const grecaptcha = await loadRecaptcha()

      grecaptcha.ready(async () => {
        const gRecaptchaToken = await window.grecaptcha.execute(
          config.reCaptchaSiteKey,
          { action: 'submit' }
        )
        const res = await fetch('/api/contact-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ ...data, gRecaptchaToken })
        })
        setLoading(false)
        if (res.status !== 200) {
          const errors = await res.json()
          Object.keys(errors).forEach((field) =>
            setFormError(field, { type: 'manual', message: errors[field] })
          )
          setError('There was an issue submitting this form.')
          return
        }
        setSuccess(true)
      })
    } catch (error) {
      setLoading(false)
      setError(
        'There was an issue submitting this form. Please try again later.'
      )
    }
  })

  return (
    <>
      <ContactForm
        title={title}
        formType={formType}
        country={country}
        error={error}
        errors={errors}
        success={success}
        loading={loading}
        register={register}
        watch={watch}
        control={control}
        onSubmit={onSubmit}
        __url={__url}
      />
    </>
  )
}

ContactFormWidget.propTypes = {
  title: string.isRequired,
  formType: string.isRequired,
  country: string.isRequired,
  __url: func.isRequired
}

export default ContactFormWidget
